export const appsDataList = [
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Twilio SMS/MMS",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zohodesk",
    logoImage: "logo-image",
    rightServiceLogo: "twilio",
    description: "Inbound and outbound support",
    zohodeskVideo:true,
    overallReview: true,
    comingSoonBanner: false,
    showImportantCard:true,
    filterBy: "filter-one filter-message zohodesk twilio",
    installLinkOfcanvas:true,
    installLinkOfcanvasTest:"This plugin enables SMS & MMS communication between agents and end users & option to automatically send SMS on status change. End users can ask for help via SMS/MMS and support engineers can send the solution through the same channel.",
    list :[
            "SMS and MMS Inbound and Outbound Support",
            "SMS Template Support",
            "SMS Outbound Initiator",
            "Instant Acknowledgement Support"],
        
    installationLink:
      "https://marketplace.zoho.com/app/preview/desk/zoho-desk-twilio-sms-integration",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/zohodesk-twilio-sms-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Twilio SMS",
    portfolioImageOne: "portfolioImageTemplate",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "twilio",
    description: "Inbound and outbound support",
    zohocrmVideo:true,
    overallReview: true,
    comingSoonBanner: false,
    installLinkOfcanvas:true,
    showImportantCard:true,
    filterBy: "filter-crm filter-message zohocrm twilio",
    installLinkOfcanvasTest:"The Twilio SMS MMS integration for Zoho CRM allows for seamless SMS communication between agents and customers through the Zoho CRM user interface. You can send both templated and custom SMS messages, as well as MMS messages, to clients anywhere in the world. Furthermore, you can automate your SMS communications with the help of Zoho CRM workflow rules.",
    list :[
            "SMS and MMS Inbound and Outbound Support",
            "SMS Template Support",
            "SMS Outbound Initiator",
            "Instant Acknowledgement Support",
        ],
    installationLink: "https://marketplace.zoho.com/app/crm/twiliosmszohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/zoho-crm-twilio-sms-integration",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Twilio Bulk SMS",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "twilio",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    showImportantCard:true,
    filterBy: "filter-one filter-message zohodesk twilio",
    installationLink:
      "https://marketplace.zoho.com/app/desk/twilio-bulk-sms-and-multiple-number-support-extension-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/zohodesk-twilio-sms-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Telnyx",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "telnyx",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-crm filter-message zohocrm telnyx",
    installationLink:
      "https://marketplace.zoho.com/app/crm/telnyx-sms-for-zoho-crm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/zoho-crm-telnyx-sms-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Plivo",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "plivo",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-crm filter-message zohocrm plivo",
    installationLink:
      "https://marketplace.zoho.com/app/crm/plivo-sms-extension-for-zoho-crm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/plivo-sms-for-zoho-crm",
  },

  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "RingCentral",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "ringcentral",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-crm filter-message zohocrm ringcentral",
    installationLink:
      "https://marketplace.zoho.com/app/crm/ringcentral-high-volume-sms-for-zoho-crm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/ringcentral-zohocrm",
  },
  {
    leftServiceName: "Shopify",
    rightServiceName: "Twilio",
    leftServiceLogo: "shopify",
    rightServiceLogo: "twilio",
    description: "Outbound Support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sp filter-message shopify twilio",
    installationLink:
      "https://apps.shopify.com/oapps_twilio_sms?st_source=autocomplete",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/1-click-bulk-twilio-sms-shopify",
  },
  {
    leftServiceName: "Pipedrive",
    rightServiceName: "Twilio",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "pipedrive",
    rightServiceLogo: "twilio",
    description: "Inbound and outbound support",
    overallReview: true,
    pipedriveVideo:true,
    comingSoonBanner: false,
    filterBy: "filter-pd filter-message pipedrive twilio",
    installLinkOfcanvas:true,
    installLinkOfcanvasTest:"Activate SMS in Pipedrive to send personalized single/bulk messages, and track all messages in notes under corresponding Leads/Contacts/Deals.",
    list :[
            "Effectively communicate with your clients worldwide by rapidly sending and receiving personalized or templated SMS/MMS messages. Optimize your business communication with Twilio SMS/MMS for Pipedrive.",
            "Outbound Messages Send SMS/MMS messages to Pipedrive Deals, Contacts, or Leads modules using Twilio SMS/MMS.",
            "Inbound Messages Receive SMS/MMS from any number with Pipedrive messaging.",
            "SMS History All inbound and outbound messages will be automatically recorded under their respective Deals, Contacts, or Leads in the Notes section with a timeline.",
            "Inbox Manage and view both incoming and outgoing messages in a conversational format by tracking them in one centralized location.",
            "Templated Messages Save unlimited templates with mail merge fields and friendly names for sending personalized messages.",
            "Bulk SMS Seamlessly send personalized bulk messages with just a few clicks.",
            "Automated SMS Automate the Templated or customized SMS. Coming Soon",
            "Prerequisite : 1.Pipedrive Account 2.Twilio Account & SMS Feature enabled number. 3.Start a Subscription to enable a 3-day Free trial.",
        ],
    installationLink:
      "https://www.pipedrive.com/en/marketplace/app/twilio-sms-mms-for-pipedrive/1fce535308ea4267",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/pipedrive-twilio-sms#Key_Points",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "ActiveCampaign",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "activecampaign",
    description: "Two way sync solution - OSync",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm  activecampaign datasync",
    installationLink:
      "https://marketplace.zoho.com/app/crm/osyncforactivecampaignandzohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Salesloft",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "salesloft",
    description: "Two way sync solution - OSync",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm  salesloft datasync",
    installationLink: "https://marketplace.zoho.com/app/crm/osync-for-zoho-crm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Outreach",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "outreach",
    description: "Two way sync solution - OSync",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm outreach datasync",
    installationLink:
      "https://marketplace.zoho.com/app/crm/osyncforoutreachandzohocrm0",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "HubSpot",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "hubspot",
    description: "Two way sync solution - OSync",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm  hubspot datasync",
    installationLink:
      "https://marketplace.zoho.com/app/crm/osyncforhubspotcrmandzohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Intercom",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "intercom",
    description: "Two way sync solution - OSync",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm  intercom datasync",
    installationLink:
      "https://marketplace.zoho.com/app/crm/osyncforintercomandzohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "monday.com",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "monday",
    description: "Two way sync solution - OSync",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm  monday datasync",
    installationLink:
      "https://marketplace.zoho.com/app/crm/osyncformondayandzohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "ChartMogul",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "chartMogul",
    description: "Access ChartMogul details in zoho crm",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-sync zohocrm chartMogul datasync",
    installationLink:
      "https://marketplace.zoho.com/app/crm/chartmogul-integration-for-zoho-crm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/intercom-zohocrm-data-sync-integration",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Salesloft",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "salesloft",
    description: "Access salesloft data in zoho desk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one zohodesk salesloft",
    installationLink:
      "https://marketplace.zoho.com/app/desk/salesloft-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/salesloft-for-zoho-desk",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "ActiveCampaign",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "activecampaign",
    description: "Access ActiveCampaign data in zoho desk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one zohodesk activecampaign",
    installationLink:
      "https://marketplace.zoho.com/app/desk/active-campaign-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/activecampaign-for-zoho-desk",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Insightly",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "insightly",
    description: "Access insightly data in zoho desk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one zohodesk insightly",
    installationLink:
      "https://marketplace.zoho.com/app/desk/insightly-crm-integration-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/insightly-for-zoho-desk",
  },
  // {
  //     leftServiceName: "Zoho Desk",
  //     rightServiceName: "Zoom",
  //     leftServiceLogo: "zohodesk",
  //     rightServiceLogo: "zoom",
  //     description: "inbound and outbound support",
  //     overallReview: true,
  //     comingSoonBanner: false,
  //     filterBy: "filter-desk filter-message zoho Desk zoom",
  //     installationLink: "https://marketplace.zoho.com/app/desk/zoho-desk----zoom-intgration",
  //     helpLink: "https://help.oapps.xyz/portal/en/kb/articles/zoom-for-zoho-desk"
  // },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "WorkflowMax",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "workflow",
    description: "Access  WorkflowMax details in zoho desk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one filter-message zohodesk workflow",
    installationLink:
      "https://marketplace.zoho.com/app/desk/zoho-desk----workflow-max-integration",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/workflowmax-for-zoho-desk",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Vimeo",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "vimeo",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one filter-message zohodesk vimeo",
    installationLink:
      "https://marketplace.zoho.com/app/desk/vimeo-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/vimeo-for-zoho-desk",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Viber Chatbot",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "viberchatbot",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one filter-message zohodesk viberchatbot",
    installationLink:
      "https://marketplace.zoho.com/app/desk/viber-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/viber-for-zoho-desk",
  },
  {
    leftServiceName: "Zoho Desk",
    rightServiceName: "Telnyx SMS",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zohodesk",
    rightServiceLogo: "telnyx",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-one filter-message zohodesk telnyx",
    installationLink:
      "https://marketplace.zoho.com/app/desk/telnyx-sms-mms-integration-for-zoho-desk",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/telnyx-for-zoho-desk",
  },

  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "ActiveCampaign",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "activecampaign",
    description: "Contact and Deal Support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-crm zohocrm activecampaign",
    installationLink:
      "https://marketplace.zoho.com/app/crm/activecampaignforzohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/zoho-crm-activecampaign-integration",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Phone Verifier",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "phoneverifier",
    description: "Validate the Phone number Support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-crm zohocrm phoneverifier",
    installationLink:
      "https://marketplace.zoho.com/app/crm/phoneverifierforzohocrm",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/phone-verifier-for-zoho-crm",
  },
  {
    leftServiceName: "Zoho CRM",
    rightServiceName: "Vonage",
    leftServiceLogo: "zohocrm",
    rightServiceLogo: "vonage",
    description: "Outbound Support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-crm filter-message zohocrm vonage",
    installationLink:
      "https://marketplace.zoho.com/app/crm/zohocrmvonageintegration1",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/vonage-zoho-crm-integration",
  },
  {
    leftServiceName: "Freshdesk",
    rightServiceName: "Twilio",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "freshdesk",
    rightServiceLogo: "twilio",
    description: "Inbound and Outbound Support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-fd filter-message freshdesk twilio",
    installationLink:
      "https://www.freshworks.com/apps/freshdesk/twilio_bulk_sms/",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/freshdesk-twilio-bulk-sms-integration",
  },

  {
    leftServiceName: "Zendesk",
    rightServiceName: "Salesloft",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zendesk",
    rightServiceLogo: "salesloft",
    description: "Access Salesloft data in Zendesk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zde zendesk salesloft",
    installationLink:
      "https://www.zendesk.com/marketplace/apps/support/515337/salesloft-by-oapps/",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/salesloft-for-zendesk",
  },
  {
    leftServiceName: "Zendesk",
    rightServiceName: "ActiveCampaign",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zendesk",
    rightServiceLogo: "activecampaign",
    description: "Access ActiveCampaign data in Zendesk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zde zendesk activecampaign",
    installationLink:
      "https://www.zendesk.com/marketplace/apps/support/495720/active-campaign-by-oapps/",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/active-campaign-for-zendesk",
  },
  {
    leftServiceName: "Zendesk Sell",
    rightServiceName: "Zoho Sign",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zendesksell",
    rightServiceLogo: "zohosign",
    description: "Upload document to Zoho Sign",
    overallReview: false,
    comingSoonBanner: true,
    filterBy: "filter-zde filter-upcoming zendesk sell zohosign zohosign",
    installationLink: "#",
    helpLink: "#",
  },
  {
    leftServiceName: "Salesforce",
    rightServiceName: "Vonage",
    leftServiceLogo: "salesforce",
    rightServiceLogo: "vonage",
    description: "Send Vonage SMS",
    overallReview: false,
    comingSoonBanner: true,
    filterBy: "filter-message filter-sf filter-upcoming salesforce vonage",
    installationLink: "#",
    helpLink: "https://help.oapps.xyz/portal/en/kb/articles/vonage-salesforce",
  },
  {
    leftServiceName: "Zoho Sign",
    rightServiceName: "HubSpot",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zohosign",
    rightServiceLogo: "hubspot",
    description: "Upload document to Zoho Sign",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zs zohosign hubspot zohosign",
    installationLink:
      "https://ecosystem.hubspot.com/marketplace/apps/sales/sales-enablement/zoho-sign-286004",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/zoho-sign-for-hubspot",
  },
  {
    leftServiceName: "Zoho Projects",
    rightServiceName: "Sugar CRM",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohoprojects",
    rightServiceLogo: "sugarcrm",
    description: "Map Sugar CRM Modules",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zp zoho projects sugarcrm",
    installationLink:
      "https://marketplace.zoho.com/app/projects/sugarcrm-integration-for-zoho-projects",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/sugarcrm-zoho-projects",
  },
  {
    leftServiceName: "Zoho Mail",
    rightServiceName: "Wrike",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zohomail",
    rightServiceLogo: "wrike",
    description: "Create new tasks from Emails",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zm zohomail wrike",
    installationLink:
      "https://marketplace.zoho.com/app/mail/wrike-for-zoho-mail",
    helpLink: "https://help.oapps.xyz/portal/en/kb/articles/wrike-zoho-mail",
  },
  {
    leftServiceName: "Zoho Mail",
    rightServiceName: "monday.com",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zohomail",
    rightServiceLogo: "monday",
    description: "Create new tasks from Emails",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zm zohomail monday",
    installationLink:
      "https://marketplace.zoho.com/app/mail/zoho-mail-monday.com-integration",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/monday-for-zoho-mail-integration",
  },

  {
    leftServiceName: "Pipedrive",
    rightServiceName: "RingCentral",
    leftServiceLogo: "pipedrive",
    rightServiceLogo: "ringcentral",
    description: "Inbound and outbound support",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-pd filter-message pipedrive ringcentral",
    installationLink:
      "https://www.pipedrive.com/en/marketplace/app/ring-central-bulk-sms-for-pipe-drive/bf3b6e48d57d2ce6",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/pipedrive-ringcentral-sms-integration",
  },

  {
    leftServiceName: "Pipedrive",
    rightServiceName: "Zoho Sign",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "pipedrive",
    rightServiceLogo: "zohosignrightside",
    description: "Upload document to Zoho Sign",
    overallReview: false,
    comingSoonBanner: false,
    filterBy: "filter-pd pipedrive zohosign zohosign",
    installationLink:
      "https://www.pipedrive.com/en/marketplace/app/zoho-sign-for-pipedrive/f8664c597ba1ee5c",
    helpLink:
      "https://help.oapps.xyz/portal/en/kb/articles/pipedrive-zoho-sign-integration",
  },
  {
    leftServiceName: "Zendesk",
    rightServiceName: "Twilio",
    portfolioImageOne: "portfolioImageRed",
    leftServiceLogo: "zendesk",
    rightServiceLogo: "twilio",
    description: "Enable SMS Channel for Zendesk",
    overallReview: true,
    comingSoonBanner: false,
    filterBy: "filter-zde zendesk twilio",
    installationLink:
      "https://www.zendesk.com/in/marketplace/apps/support/843460/twilio-sms-by-oapps-/",
    helpLink: "https://help.oapps.xyz/portal/en/kb/articles/twilio-sms-zendesk",
  },
  {
    leftServiceName: "Zendesk",
    rightServiceName: "Plivo",
    portfolioImageOne: "portfolioImageSkyBlue",
    leftServiceLogo: "zendesk",
    rightServiceLogo: "plivo",
    description: "Enable SMS Channel for Zendesk",
    overallReview: false,
    comingSoonBanner: false,
    filterBy: "filter-zde filter-upcoming zendesk plivo",
    installationLink:
      "https://www.zendesk.com/marketplace/apps/support/849622/plivo-sms-by-oapps/",
    helpLink: "#",
  },
  {
    leftServiceName: "Zendesk",
    rightServiceName: "RingCentral",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "zendesk",
    rightServiceLogo: "ringcentral",
    description: "Enable SMS Channel for Zendesk",
    overallReview: false,
    comingSoonBanner: false,
    filterBy: "filter-zde filter-upcoming zendesk ringcentral",
    installationLink:
      "https://www.zendesk.com/marketplace/apps/support/848590/ringcentral-sms-by-oapps/",
    helpLink: "#",
  },
  {
    leftServiceName: "Zendesk",
    rightServiceName: "Telnyx",
    leftServiceLogo: "zendesk",
    rightServiceLogo: "telnyx",
    description: "Enable SMS Channel for Zendesk",
    overallReview: false,
    comingSoonBanner: false,
    filterBy: "filter-zde filter-upcoming zendesk telnyx",
    installationLink:
      "https://www.zendesk.com/marketplace/apps/support/849625/telnyx-sms-by-oapps-/",
    helpLink: "#",
  },
  {
    leftServiceName: "HubSpot",
    rightServiceName: "OMNI Channel Messgaing",
    portfolioImageOne: "portfolioImageBlue",
    leftServiceLogo: "hubspotLeftService",
    rightServiceLogo: "omni",
    description: "OMNI Messaging Channel for HubSpot",
    overallReview: false,
    comingSoonBanner: false,
    filterBy: "filter-hs omni hubspot",
    installationLink: "https://rebrand.ly/hubspotOmni",
    helpLink: "https://help.oapps.xyz/portal/en/kb/articles/hubspotomnichannel",
  },
];
