import React  from 'react';
import HubspotHeaderPage from "./HubspotMain";
import HubSpotOmniApps from "./hubspotOmni"


const OappsParterHubspot = () => {
    
      return (
      <>
        <HubspotHeaderPage/>
        <HubSpotOmniApps/>
        
        
        </>
      );
    };

export default OappsParterHubspot