import React  from 'react';
import ShopifyHeaderPage from "./ShopifyMain";
import ShopifyTwilioApps from "./ShopifyTwilio"
import ShopifyOmniApps from "./shopifyOmni"

const OappsParterShopify = () => {
    
      return (
      <>
        <ShopifyHeaderPage/>
        <ShopifyTwilioApps/>
        <ShopifyOmniApps/>
        </>
      );
    };

export default OappsParterShopify