import React from "react";

import OappsParterZoho from "./PartnerZoho";

import { Routes, Route } from "react-router-dom";

const PartnerZoho= () => {
  return (
    <Routes>
      <Route exact path="/SMS-For-Zoho" element={<OappsParterZoho />} />
    </Routes>
  );
}

export default PartnerZoho;
