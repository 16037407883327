import React  from 'react';
import PipedriveHeaderPage from "./PipedriveMain";
import PipedriveTwilio from "./PipedriveTwilio"
import PipedriveRingcentralApps from './PipedriveRingcentral'

const OappsParterPipedrive = () => {
    
      return (
      <>
        <PipedriveHeaderPage/>
        <PipedriveTwilio/>
        <PipedriveRingcentralApps/>
        
        </>
      );
    };

export default OappsParterPipedrive