import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import frFR from '../node_modules/antd/lib/locale-provider/fr_FR';

// import frFR from 'antd/lib/locale-provider/fr_FR';
// import 'moment/locale/fr';



const root = ReactDOM.createRoot(document.getElementById('root'),);
root.render(
  <React.StrictMode>
   
    <App />
    
  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
