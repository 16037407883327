import React from 'react';
import { Typography, Row, Col, Card, Avatar } from 'antd';
import "../assets/About.css"
import ourTeamOne from "../img/Team/ourTeam.jpg"

const { Title } = Typography;
const { Meta } = Card;



const About = () => {
  return (
    <div id="about" className='aboutSpace' style={{backgroundColor:"white"}}>
      <Row >
        <Col span={24} align="center">
          <Title level={2} style={{marginBottom:'50px'}}>Team</Title>
          
          <Row align="middle" justify="center">  
              <img style={{width:"90%", height:"90%"}} className='image'  src={ourTeamOne} alt="Image1" />
              <Title  level={2} className="overlay-text overlay-textMedia"> Meet Our Dynamic Team of Innovators, Collaborators, and Problem-Solvers, Dedicated to Turning Your Visions into Reality.
</Title>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default About;
