const Blob = () => {
    
    
    
      return (<>
        
                <svg className="blobOne" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#837FCB" d="M37.1,-25.2C47.4,-16.7,54.5,-1.3,50,8.6C45.5,18.5,29.3,23,15.4,28.2C1.5,33.5,-10.2,39.5,-22.6,37.3C-35,35,-48,24.5,-51.2,11.4C-54.5,-1.7,-48,-17.4,-37.8,-25.9C-27.7,-34.3,-13.8,-35.6,-0.2,-35.4C13.4,-35.2,26.8,-33.7,37.1,-25.2Z" transform="translate(100 100)"></path>
                </svg>

                <svg className="blobSec" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#837FCB" d="M48,-10.8C56.2,9.7,52.8,38.8,37.8,49C22.8,59.2,-3.8,50.5,-24.2,35.2C-44.7,19.9,-59,-2,-53.9,-18.2C-48.9,-34.4,-24.4,-45,-2.3,-44.2C19.9,-43.5,39.7,-31.4,48,-10.8Z" transform="translate(100 100)"></path>
                </svg>


                <svg className="blobThird" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#837FCB" d="M45.3,-16.1C52.1,6.1,46.3,31,31.3,41.5C16.3,52,-8,48.2,-30,33.9C-52.1,19.6,-72,-5.1,-66.6,-25.4C-61.2,-45.7,-30.6,-61.5,-5.7,-59.7C19.3,-57.9,38.5,-38.3,45.3,-16.1Z" transform="translate(100 100)"></path>
                </svg>


                <svg className="blobFour" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#837FCB" d="M49.2,-14.2C58.8,13.6,58.2,46.5,43.2,56.7C28.2,66.9,-1.2,54.6,-18.6,38.8C-35.9,23.1,-41.1,4,-36,-17.5C-30.9,-39.1,-15.4,-63.1,2.2,-63.8C19.8,-64.5,39.6,-41.9,49.2,-14.2Z" transform="translate(100 100)"></path>
                </svg>


                <svg className="blobFive" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#837FCB" d="M54.1,-26.7C58.3,-4.9,41.5,14.6,23.8,25.8C6.1,37,-12.6,40,-27.3,30.9C-42.1,21.8,-52.9,0.5,-47.7,-22.7C-42.5,-46,-21.2,-71.2,1.9,-71.8C25,-72.4,50,-48.4,54.1,-26.7Z" transform="translate(100 100)"></path>
                </svg>
          
        </>
      );
    };
    export default Blob;
